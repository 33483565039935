<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <h4 class="mb-5">Cliente {{ formatedClients.b2b ? "B2B" : "" }}</h4>

        <b-form>
          <b-row>
            <b-col md="3">
              <form-input
                v-model="formatedClients.cpfCnpj"
                disabled
                icon="person-lines-fill"
                label="CPF/CNPJ"
              ></form-input>
            </b-col>
            <b-col>
              <form-input
                v-model="formatedClients.name"
                disabled
                icon="person-lines-fill"
                label="Razão Social"
              ></form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <form-input
                v-model="formatedClients.phone1"
                icon="telephone"
                label="Telefone"
                :errors="errors['phone1']"
              ></form-input>
            </b-col>

            <b-col>
              <form-input
                v-model="formatedClients.phone2"
                icon="telephone"
                label="Telefone 2"
                :errors="errors['phone2']"
              ></form-input>
            </b-col>

            <b-col md="5">
              <form-input
                v-model="formatedClients.email"
                disabled
                icon="at"
                label="E-mail"
              ></form-input>
            </b-col>

            <b-col>
              <form-input
                v-model="formatedClients.birthDate"
                disabled
                icon="calendar3"
                label="Data de abertura:"
              ></form-input>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <form-select
                v-model="formatedClients.discountTableId"
                :options="discountTableList"
                :disabled="!formatedClients.b2b"
                :errors="errors['discountTableId']"
                icon="coin"
                label="Tabela de Desconto"
                textField="description"
              ></form-select>
            </b-col>
            <b-col md="2">
              <form-number
                v-model="formatedClients.extraDiscount"
                icon="percent"
                label="Desconto extra"
                :disabled="!formatedClients.b2b"
                :errors="errors['extraDiscount']"
              ></form-number>
            </b-col>

            <b-col md="2">
              <form-number
                v-model="formatedClients.admDiscount"
                icon="percent"
                label="Desconto administrativo"
                :disabled="!formatedClients.b2b"
                :errors="errors['admDiscount']"
              ></form-number>
            </b-col>

            <b-col>
              <form-input
                :value="
                  formatedClients.authenticated
                    ? 'Cliente autenticado'
                    : 'O cliente ainda não foi autenticado'
                "
                :icon="formatedClients.authenticated ? 'check2' : 'x'"
                label="Estado atual do cliente"
                disabled
              >
              </form-input>
            </b-col>
          </b-row>
        </b-form>

        <b-table
          :items="formatedClients.client_address"
          :fields="addressFields"
          bordered
          striped
          hover
          caption-top
          class="mt-5"
        >
          <template #table-caption>
            <div
              class="d-flex justify-content-center"
              v-if="errors['client_address']"
            >
              <span class="text-secondary">
                {{ errors["client_address"][0] }}
              </span>
            </div>
          </template>
          <template #cell(actions)="row">
            <b-button
              variant="primary"
              size="sm"
              title="Editar endereço"
              @click="openEditAddressModal(row.item)"
            >
              <b-icon icon="pencil"></b-icon>
            </b-button>
            <b-button
              size="sm"
              class="ml-2"
              title="Deletar endereço"
              @click="openDeleteAddressModal(row.item)"
            >
              <b-icon icon="x-circle"></b-icon>
            </b-button>
          </template>
        </b-table>

        <div class="d-flex justify-content-end">
          <b-button class="mr-2" variant="primary" @click="openNewAddressModal"
            >ADICIONAR NOVO ENDEREÇO</b-button
          >

          <b-button variant="primary" @click="updateClientInfo"
            >SALVAR</b-button
          >
        </div>

        <b-modal
          v-model="editAddressModal"
          hide-footer
          size="xl"
          title="Modificar informações de endereço"
        >
          <b-row>
            <b-col md="4">
              <form-input
                v-model="addressInfo.zipCode"
                icon="signpost-split"
                label="CEP:"
                :errors="errors['zipCode']"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <form-input
                v-model="addressInfo.address"
                icon="signpost-split"
                label="Endereço:"
                :errors="errors['address']"
              />
            </b-col>

            <b-col md="3">
              <form-input
                v-model="addressInfo.number"
                icon="mailbox"
                label="Número:"
                :errors="errors['number']"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <form-input
                v-model="addressInfo.district"
                icon="map"
                label="Bairro:"
                :errors="errors['district']"
              />
            </b-col>

            <b-col>
              <form-input
                v-model="addressInfo.city"
                icon="signpost-split"
                label="Cidade:"
                :errors="errors['city']"
              />
            </b-col>

            <b-col md="2">
              <form-input
                v-model="addressInfo.state"
                icon="signpost-split"
                label="Estado:"
                :errors="errors['state']"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <form-input
                v-model="addressInfo.references"
                icon="signpost-split"
                label="Referência:"
                :errors="errors['references']"
              />
            </b-col>
            <b-col>
              <form-input
                v-model="addressInfo.complement"
                icon="card-text"
                label="Complemento:"
                :errors="errors['complement']"
              />
            </b-col>
          </b-row>
          <div class="d-flex justify-content-end">
            <b-button variant="primary" @click="updateAddress">SALVAR</b-button>
          </div>
        </b-modal>

        <b-modal
          v-model="newAddressModal"
          title="Insira as informações de endereço"
          hide-footer
          size="xl"
        >
          <b-form @submit.prevent="createNewAddress">
            <b-row>
              <b-col md="4">
                <form-input
                  v-model="newAddressInfo.zipCode"
                  icon="signpost-split"
                  label="CEP:"
                  required
                  @change="getInfoByCep"
                  @enter="getInfoByCep"
                  :errors="errors['zipCode']"
                ></form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-input
                  v-model="newAddressInfo.address"
                  icon="signpost-split"
                  label="Endereço:"
                  required
                  :errors="errors['address']"
                ></form-input>
              </b-col>
              <b-col md="3">
                <form-input
                  v-model="newAddressInfo.number"
                  icon="mailbox"
                  label="Numero:"
                  required
                  :errors="errors['number']"
                ></form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-input
                  v-model="newAddressInfo.district"
                  icon="map"
                  label="Bairro:"
                  required
                  :errors="errors['district']"
                ></form-input>
              </b-col>

              <b-col>
                <form-input
                  v-model="newAddressInfo.city"
                  icon="pin-map"
                  label="Cidade:"
                  required
                  :errors="errors['city']"
                ></form-input>
              </b-col>
              <b-col md="2">
                <form-input
                  v-model="newAddressInfo.state"
                  icon="signpost-split"
                  label="Estado:"
                  required
                  :errors="errors['state']"
                ></form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <form-input
                  v-model="newAddressInfo.references"
                  icon="signpost-split"
                  label="Referência:"
                  :errors="errors['references']"
                ></form-input>
              </b-col>
              <b-col>
                <form-input
                  v-model="newAddressInfo.complement"
                  icon="card-text"
                  label="Complemento:"
                  :errors="errors['complement']"
                ></form-input>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end">
              <b-button variant="primary" type="submit">SALVAR</b-button>
            </div>
          </b-form>
        </b-modal>

        <b-modal
          title="Deseja deletar endereço?"
          v-model="deleteAddressModal"
          hide-footer
        >
          <p>
            O endereço selecionado irá ser deletado permanentemente da nossa
            base de dados
          </p>
          <div class="d-flex justify-content-end">
            <b-button variant="danger" @click="deleteAddress">DELETAR</b-button>
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import http from "../../plugins/http";
import FormInput from "../../components/form/FormInput.vue";
import { mapMutations } from "vuex";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";
import { maskBr } from "js-brasil";
import axios from "axios";
import FormSelect from "../../components/form/FormSelect.vue";
import FormNumber from "../../components/form/FormNumber.vue";

export default {
  components: { FormInput, FormSelect, FormNumber },
  data() {
    return {
      clientInfo: {
        name: "",
        phone1: "",
        phone2: "",
        email: "",
        cpfCnpj: "",
        createdAt: "",
        extraDiscount: "",
        admDiscount: "",
        discountTableId: null,
        client_address: [],
      },
      errors: {},
      addressFields: [
        {
          key: "address",
          label: "Endereço",
        },
        {
          key: "number",
          label: "Número",
        },
        {
          key: "complement",
          label: "Complemento",
        },
        {
          key: "district",
          label: "Bairro",
        },
        {
          key: "zipCode",
          label: "CEP",
        },
        {
          key: "references",
          label: "Referência",
        },
        {
          key: "state",
          label: "Estado",
        },
        {
          key: "city",
          label: "Cidade",
        },
        {
          key: "actions",
          label: "Ações",
        },
      ],

      addressInfo: {
        address: "",
        city: "",
        number: "",
        zipCode: "",
        complement: "",
        district: "",
        state: "",
        references: "",
      },

      newAddressInfo: {
        address: "",
        city: "",
        number: "",
        zipCode: "",
        complement: "",
        district: "",
        state: "",
        references: "",
      },
      discountTableList: [{ id: null, description: "SEM TABELA DE DESCONTO" }],
      editAddressModal: false,
      addressId: null,
      newAddressModal: false,
      deleteAddressModal: false,
      addressIdToDelete: null,
    };
  },
  computed: {
    formatedClients() {
      return {
        ...this.clientInfo,
        cpfCnpj: formatCpfCnpj(this.clientInfo.cpfCnpj),
        phone1:
          this.clientInfo.phone1.length > 10
            ? maskBr.celular(this.clientInfo.phone1)
            : maskBr.telefone(this.clientInfo.phone1),
        phone2: this.clientInfo.phone2
          ? this.clientInfo.phone2.length > 10
            ? maskBr.celular(this.clientInfo.phone2)
            : maskBr.telefone(this.clientInfo.phone2)
          : null,
        birthDate: new Date(this.clientInfo.birthDate).toLocaleString("pt-BR"),
        client_address: this.clientInfo.client_address.map((address) => {
          return {
            ...address,
            zipCode: maskBr.cep(address.zipCode),
          };
        }),
      };
    },
  },
  methods: {
    ...mapMutations(["setOverlay", "setToastedSuccess", "setToastedError"]),

    openDeleteAddressModal(item) {
      this.deleteAddressModal = true;
      this.addressIdToDelete = item.id;
    },

    openNewAddressModal() {
      this.errors = {};
      this.newAddressInfo = {
        address: "",
        city: "",
        number: "",
        zipCode: "",
        complement: "",
        district: "",
        state: "",
        references: "",
      };
      this.newAddressModal = true;
    },

    openEditAddressModal(item) {
      this.errors = {};
      this.addressInfo = {
        address: item.address,
        city: item.city,
        number: item.number,
        zipCode: item.zipCode,
        complement: item.complement,
        district: item.district,
        state: item.state,
        references: item.references,
      };
      this.editAddressModal = true;
      this.addressId = item.id;
    },

    async deleteAddress() {
      try {
        this.setOverlay(true);
        this.deleteAddressModal = false;
        await http.delete(`/client/address/${this.addressIdToDelete}`);

        const addresses = this.clientInfo.client_address.filter(
          (address) => address.id !== this.addressIdToDelete
        );

        this.clientInfo.client_address = addresses;
      } catch (error) {
        this.setToastedError({
          message: error.response.data.error,
        });
      }
      this.setOverlay(false);
    },

    async createNewAddress() {
      this.errors = {};
      try {
        const { data } = await http.post(
          `/client/address/${this.formatedClients.id}`,
          this.newAddressInfo
        );
        this.clientInfo.client_address.push(data);
        this.newAddressModal = false;
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
    },

    async updateClientInfo() {
      this.errors = {};
      try {
        this.setOverlay(true);
        await http.put(`client/${this.formatedClients.id}`, {
          phone1: this.formatedClients.phone1,
          phone2: this.formatedClients.phone2,
          discountTableId: this.formatedClients.discountTableId,
          extraDiscount: this.formatedClients.extraDiscount,
          admDiscount: this.formatedClients.admDiscount,
        });
        this.setToastedSuccess({
          message: "As modificações foram aplicadas",
        });
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
      this.setOverlay(false);
    },

    async updateAddress() {
      this.errors = {};
      try {
        const { data } = await http.put(
          `/client/updateAddress/${this.addressId}`,
          this.addressInfo
        );

        const index = this.clientInfo.client_address.findIndex(
          (element) => element.id === data.id
        );
        this.clientInfo.client_address.splice(index, 1, data);
        this.editAddressModal = false;
        this.setToastedSuccess({
          message: "As modificações foram aplicadas",
        });
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
    },

    async getInfoByCep(cep) {
      const { data } = await axios(
        `https://brasilapi.com.br/api/cep/v1/${cep}`
      );
      this.newAddressInfo = {
        ...this.newAddress,
        address: data.street,
        district: data.neighborhood,
        zipCode: data.cep,
        state: data.state,
        city: data.city,
      };
    },
  },
  async created() {
    this.setOverlay(true);
    const id = this.$route.params.id;
    const { data: client } = await http(`/client/${id}`);
    this.clientInfo = client;

    const { data: discount } = await http(`/discount`);

    this.discountTableList = [...this.discountTableList, ...discount];

    this.setOverlay(false);
  },
};
</script>
